// --- Header Scroll Class ---

var html = document.querySelector('html');

var header = document.querySelector('#header-wrapper');

var toggler = header.querySelector('button.navbar-toggler');

if (header) {

    document.addEventListener('scroll', function () {
        var scrolled = html.scrollTop;
        if (scrolled > 100) {
            header.classList.add('header-scrolled');
        } else {
            header.classList.remove('header-scrolled');
        }
    });

    toggler.addEventListener('click', function () {
        header.classList.add('header-scrolled');
    });

};

// --- Megamenu visibility ---

var hasMegamenu = document.querySelectorAll('.has-megamenu');

if (hasMegamenu.length) {

    hasMegamenu.forEach(function (el) {
        
        el.addEventListener('mouseover', function () {
            el.classList.add('visible');
        });      

        el.addEventListener('mouseout', function () {
            el.classList.remove('visible');
        });
        
    });

};

// --- Megamenu third level visibility ---

var secondLevelLink = document.querySelectorAll('.second-level-link');

if (secondLevelLink.length) {

    secondLevelLink.forEach(function (el) {
        
        el.addEventListener('mouseover', function () {
            el.classList.add('visible');
        });      

        el.addEventListener('mouseout', function () {
            el.classList.remove('visible');
        });
        
    });

};

// --- Megamenu third level layout ---

var thirdLevel = document.querySelectorAll('.megamenu-third-level');

if (thirdLevel.length) {

    thirdLevel.forEach(function (el) {    
                   
        var mmLeft = el.closest('.megamenu-left');
        var mmCenter = el.closest('.megamenu').querySelector('.megamenu-center-alt')

        el.style.left = mmLeft.offsetWidth + 'px';
        el.style.width = mmCenter.offsetWidth + 'px';

        mmLeft.style.height = el.offsetHeight + 'px';        

    });

};